.context-menu(
    :style.left='contextMenu.x+"px"',
    :style.top='contextMenu.y+"px"',
    @mouseleave='contextMenu.hide()',
    al-if='contextMenu.visible'
)
  .search.item(al-if='contextMenu.searchBar')
    input(al-value='filter')
  .item(al-repeat='(name,item) in contextMenu.searchItems(filter)',
    al-item
    )
    | {{name}}
    .subitems(al-if='contextMenu.haveSubitems(item)')
      .item(
        al-repeat='(name,subitem) in item',
        al-item
      )
        | {{name}}